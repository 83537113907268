var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.formData ? _c('div', [_c('toolbarTitle', {
    attrs: {
      "title": "Order ID: ",
      "titleRed": _vm.orderId
    }
  }), _vm.isBusinessOrder ? _c('v-card', {
    staticClass: "pa-5"
  }, [_c('v-row', [_c('v-col', [_c('h3', {
    staticClass: "pb-2",
    staticStyle: {
      "border-bottom": "1px solid #ccc"
    }
  }, [_vm._v(" Company Information ")])])], 1), _c('v-row', {
    staticClass: "mt-2 pt-0"
  }, [_c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Company Name")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(" " + _vm._s(_vm.formData.invoice.company.name ? _vm.formData.invoice.company.name : "---"))])], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Organization Number")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(" " + _vm._s(_vm.formData.invoice.company.organizationNumber ? _vm.formData.invoice.company.organizationNumber : "---"))])], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Fortnox Number")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(" " + _vm._s(_vm.formData.invoice.company.fortnoxNumber ? _vm.formData.invoice.company.fortnoxNumber : "---"))])], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Billing Reference")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(_vm._s(_vm.formData.invoice.reference ? _vm.formData.invoice.reference : "---"))])], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Other")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(_vm._s(_vm.formData.invoice.company.reference ? _vm.formData.invoice.company.reference : "---"))])], 1)], 1)], 1) : _vm._e(), _vm.isBusinessOrder ? _c('v-card', {
    staticClass: "px-5 py-4 mt-3"
  }, [_c('v-row', [_c('v-col', [_c('h3', {
    staticClass: "pb-2",
    staticStyle: {
      "border-bottom": "1px solid #ccc"
    }
  }, [_vm._v(" Authorized Employee ")])])], 1), _c('v-row', {
    staticClass: "mt-2 pt-0"
  }, [_c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Employee")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(" " + _vm._s(_vm.formData.invoice.customer.firstName ? _vm.formData.invoice.customer.firstName : "---") + " " + _vm._s(_vm.formData.invoice.customer.lastName ? _vm.formData.invoice.customer.lastName : "---"))])], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Employee E-Mail")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(_vm._s(_vm.formData.invoice.customer.email ? _vm.formData.invoice.customer.email : "---"))])], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Employee Phone")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(_vm._s(_vm.formData.invoice.customer.phone ? _vm.formData.invoice.customer.phone : "---"))])], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("IATA Number")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(_vm._s(_vm.formData.invoice.customer.companyCustomerNumber ? _vm.formData.invoice.customer.companyCustomerNumber : "---"))])], 1)], 1)], 1) : _vm._e(), _vm.isBusinessOrder ? _c('v-card', {
    staticClass: "px-5 py-4 mt-3"
  }, [_c('v-row', [_c('v-col', [_c('h3', {
    staticClass: "pb-2",
    staticStyle: {
      "border-bottom": "1px solid #ccc"
    }
  }, [_vm._v(" Customer & Delivery Information "), _c('span', {
    staticClass: "text-body-2 primary--text font-weight-bold"
  }, [_vm._v("(The person who will receive the confirmation mail, Interrail Pass and pick up the printable tickets.)")])])])], 1), _c('v-row', {
    staticClass: "mt-2 pt-0"
  }, [_c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Full Name")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(_vm._s(_vm.formData.customer.firstName) + " " + _vm._s(_vm.formData.customer.lastName))])], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Deliver E-Mail")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(_vm._s(_vm.formData.customer.email))])], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Phone")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(_vm._s(_vm.formData.customer.phone))])], 1), _vm.formData.customer.delivery.country ? _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card-title', {
    staticClass: "accent--text medium pl-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Shipping Address ")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold pl-0"
  }, [_vm._v(" " + _vm._s(_vm.formData.customer.delivery.address) + " " + _vm._s(_vm.formData.customer.delivery.address2) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.formData.customer.delivery.postalCode) + " " + _vm._s(_vm.formData.customer.delivery.city) + " " + _vm._s(_vm.formData.customer.delivery.country))])], 1) : _vm._e()], 1)], 1) : _vm._e(), !_vm.isBusinessOrder ? _c('v-card', {
    staticClass: "px-5 py-4 mt-3"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('span', {
    staticClass: "accent--text text-sm-body-2 font-weight-bold"
  }, [_vm._v("Customer E-Mail :")]), _c('span', {
    staticClass: "text-sm-body-2"
  }, [_vm._v(" " + _vm._s(_vm.formData.customer.email ? _vm.formData.customer.email : "---"))])])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('v-card', {
    staticClass: "px-5 py-4 mt-3",
    staticStyle: {
      "background-color": "#fff6d9",
      "min-height": "150px"
    }
  }, [_c('v-row', [_c('v-col', [_c('h3', {
    staticClass: "pb-2",
    staticStyle: {
      "border-bottom": "1px solid #ccc"
    }
  }, [_vm._v(" Internal Message ")])])], 1), _c('v-row', {
    staticClass: "mt-0 pt-0"
  }, [_c('v-col', {
    domProps: {
      "innerHTML": _vm._s(_vm.formData.internalMessage ? _vm.formData.internalMessage : '---')
    }
  })], 1)], 1)], 1), _c('v-col', [_c('v-card', {
    staticClass: "px-5 py-4 my-3",
    staticStyle: {
      "min-height": "150px"
    }
  }, [_c('v-row', [_c('v-col', [_c('h3', {
    staticClass: "pb-2",
    staticStyle: {
      "border-bottom": "1px solid #ccc"
    }
  }, [_vm._v(" External Message ")])])], 1), _c('v-row', {
    staticClass: "mt-0 pt-0"
  }, [_c('v-col', {
    domProps: {
      "innerHTML": _vm._s(_vm.formData.externalMessage ? _vm.formData.externalMessage : '---')
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }