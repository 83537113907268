var render = function render(){
  var _vm$user$fullName;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('toolbarTitle', {
    attrs: {
      "title": _vm.step === 1 ? 'Create Order' : _vm.isBusinessOrder === null || _vm.isBusinessOrder === false ? 'Create Private Order' : 'Create Business Order'
    }
  }), _c('v-stepper', {
    attrs: {
      "vertical": ""
    },
    model: {
      value: _vm.step,
      callback: function ($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 1,
      "step": 1
    }
  }, [_vm._v(" Order Type "), _c('small', [_vm._v(_vm._s(_vm.userCanInvoiceOrders ? "Select order type" : "Select the action you want to do"))])]), _c('v-stepper-content', {
    staticClass: "pb-5",
    attrs: {
      "step": 1
    }
  }, [_c('div', {
    staticClass: "py-1"
  }, [_c('v-btn', {
    staticStyle: {
      "height": "53px"
    },
    attrs: {
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        _vm.privateOrder(), _vm.nextStep();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.userCanInvoiceOrders ? "PRIVATE ORDER" : "CREATE ORDER") + " ")]), _vm.userCanInvoiceOrders ? _c('v-btn', {
    staticClass: "ml-4",
    staticStyle: {
      "height": "53px"
    },
    attrs: {
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        _vm.businessOrder(), _vm.nextStep();
      }
    }
  }, [_vm._v(" BUSINESS ORDER ")]) : _vm._e()], 1)]), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 2,
      "step": 2
    }
  }, [_vm._v(" Order Information "), _c('small', [_vm._v("Add basic information about the order")])]), _c('v-stepper-content', {
    staticClass: "pb-5",
    attrs: {
      "step": 2,
      "loading": _vm.isLoading
    }
  }, [_c('v-form', {
    ref: "form-2",
    staticClass: "mx-1",
    model: {
      value: _vm.form.valid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "valid", $$v);
      },
      expression: "form.valid"
    }
  }, [!_vm.isBusinessOrder ? _c('alert', {
    attrs: {
      "textBold": "The rest of the customer's information will be fetched from Klarna, and they will be able to choose their own delivery address and method."
    }
  }) : _vm._e(), _vm.isBusinessOrder ? _c('v-autocomplete', {
    staticClass: "mb-5 mt-2",
    attrs: {
      "items": _vm.companies,
      "loading": _vm.isLoading,
      "search-input": _vm.search,
      "rules": _vm.isBusinessOrder ? _vm.validate.select : [],
      "clearable": "",
      "hide-details": "",
      "return-object": "",
      "item-text": "key",
      "item-value": "id",
      "label": "Search for company...",
      "outlined": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        return _vm.getCompanyAndCustomers();
      }
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" No data available")])], 1)];
      },
      proxy: true
    }, {
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var _item$fullName;
        var item = _ref2.item;
        return [_c('v-list-item-avatar', {
          staticClass: "text-h5 font-weight-light white--text",
          attrs: {
            "color": "primary"
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "#fff"
          }
        }, [_vm._v("mdi-domain")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s((_item$fullName = item.fullName) !== null && _item$fullName !== void 0 ? _item$fullName : item.name) + " ")])], 1)];
      }
    }], null, false, 2150304893),
    model: {
      value: _vm.form.invoice.company,
      callback: function ($$v) {
        _vm.$set(_vm.form.invoice, "company", $$v);
      },
      expression: "form.invoice.company"
    }
  }) : _vm._e(), _vm.form.invoice.company && _vm.isBusinessOrder ? _c('companyInfo', {
    staticClass: "mb-5",
    attrs: {
      "company": _vm.form.invoice.company
    }
  }) : _vm._e(), _vm.form.invoice.company && _vm.isBusinessOrder ? _c('v-autocomplete', {
    staticClass: "mb-5",
    attrs: {
      "items": _vm.customers,
      "loading": _vm.isLoading,
      "search-input": _vm.searchCustomer,
      "rules": _vm.isBusinessOrder ? _vm.validate.select : [],
      "clearable": "",
      "hide-details": "",
      "return-object": "",
      "item-text": "key",
      "item-value": "id",
      "label": "Search for authorized employee...",
      "outlined": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchCustomer = $event;
      },
      "update:search-input": function ($event) {
        _vm.searchCustomer = $event;
      },
      "change": function ($event) {
        return _vm.authorizedEmplooye();
      }
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "black"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
            }
          }], null, false, 2575987712)
        }, [_c('span', [_vm._v("Select the language for the customer")])])];
      },
      proxy: true
    }, {
      key: "no-data",
      fn: function () {
        return [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" No data available")])], 1)];
      },
      proxy: true
    }, {
      key: "selection",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.companyCustomerNumber ? item.companyCustomerNumber : item.firstName + " " + item.lastName) + " ")];
      }
    }, {
      key: "item",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-list-item-avatar', {
          staticClass: "text-h5 font-weight-light white--text",
          attrs: {
            "color": item.companyCustomerNumber ? 'third' : 'primary'
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "#fff"
          }
        }, [_vm._v(_vm._s(item.companyCustomerNumber ? "mdi-pound" : "mdi-account") + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [item.companyCustomerNumber ? _c('b', [_vm._v("IATA Number:")]) : _vm._e(), _vm._v(" " + _vm._s(item.companyCustomerNumber ? item.companyCustomerNumber + " " + item.firstName + " " + item.lastName : item.firstName + " " + item.lastName) + " ")]), item.email ? _c('v-list-item-subtitle', [_vm._v(_vm._s(item.email))]) : _vm._e()], 1)];
      }
    }], null, false, 2737822047),
    model: {
      value: _vm.form.invoice.customer,
      callback: function ($$v) {
        _vm.$set(_vm.form.invoice, "customer", $$v);
      },
      expression: "form.invoice.customer"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mt-4 mb-5"
  }, [!_vm.isBusinessOrder && _vm.accountForCustomers && _vm.accountForCustomers.length > 0 ? _c('AccountAutoComplete', {
    on: {
      "modelChange": function ($event) {
        return _vm.accountChange($event);
      }
    }
  }) : _vm._e()], 1), !_vm.isBusinessOrder ? _c('v-text-field', {
    staticClass: "mb-5",
    attrs: {
      "rules": !_vm.isBusinessOrder && !_vm.relationAccount ? _vm.validate.email : [],
      "disabled": _vm.relationAccount !== null,
      "label": "Customer E-mail (the payment link will be sent to this address)",
      "hide-details": "auto",
      "outlined": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "black"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var on = _ref6.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
            }
          }], null, false, 2575987712)
        }, [_c('span', [_vm._v("The payment link will be sent to this mail")])])];
      },
      proxy: true
    }], null, false, 3975683970),
    model: {
      value: _vm.emailModel,
      callback: function ($$v) {
        _vm.emailModel = $$v;
      },
      expression: "emailModel"
    }
  }) : _vm._e(), _c('v-select', {
    attrs: {
      "label": "Language",
      "items": _vm.system.locales,
      "outlined": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "black"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref7) {
              var on = _ref7.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
            }
          }])
        }, [_c('span', [_vm._v("Select the language for the customer")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.locale,
      callback: function ($$v) {
        _vm.$set(_vm.form, "locale", $$v);
      },
      expression: "form.locale"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Currency",
      "items": _vm.isBusinessOrder ? _vm.system.currencies.filter(function (x) {
        return x.value === 'SEK';
      }) : _vm.system.currencies,
      "item-text": "value",
      "outlined": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "black"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref8) {
              var on = _ref8.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
            }
          }])
        }, [_c('span', [_vm._v("Select the currency for customer")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.currency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "currency", $$v);
      },
      expression: "form.currency"
    }
  }), _vm.isBusinessOrder ? _c('v-text-field', {
    staticClass: "mb-5",
    attrs: {
      "label": "Reference",
      "hide-details": "auto",
      "outlined": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "black"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref9) {
              var on = _ref9.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
            }
          }], null, false, 2575987712)
        }, [_c('span', [_vm._v("Example: Conference in Stockholm")])])];
      },
      proxy: true
    }], null, false, 4134283085),
    model: {
      value: _vm.form.invoice.reference,
      callback: function ($$v) {
        _vm.$set(_vm.form.invoice, "reference", $$v);
      },
      expression: "form.invoice.reference"
    }
  }) : _vm._e(), _vm.isBusinessOrder ? _c('div', [_c('div', [_c('h3', [_vm._v("Select Ticket Type")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": !_vm.includePrintable ? 'greeny' : 'grey',
      "dark": "",
      "large": "",
      "block": ""
    },
    on: {
      "click": function ($event) {
        _vm.includePrintable = false;
      }
    }
  }, [_vm._v("ONLY E-TICKETS ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": _vm.includePrintable ? 'greeny' : 'grey',
      "dark": "",
      "large": "",
      "block": ""
    },
    on: {
      "click": function ($event) {
        _vm.includePrintable = true;
      }
    }
  }, [_vm._v("INCLUDE PRINTABLE TICKETS ")])], 1)], 1), _c('div', {
    staticClass: "mt-5"
  }, [_c('h3', [_vm._v("Customer & Delivery Information")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('p', {
    staticClass: "subtitle-2 mb-0"
  }, [_vm._v(" The person who will receive "), _c('b', [_vm._v("the confirmation mail, Interrail Pass")]), _vm._v(" and pick up "), _c('b', [_vm._v("the printable tickets")]), _vm._v(". "), _c('span', {
    staticClass: "danger--text font-weight-bold"
  }, [_vm._v("(NOT E-TICKETS)")])])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "depressed": ""
    },
    on: {
      "click": function ($event) {
        return _vm.clearCustomerForm();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-close ")]), _vm._v(" Clear Form ")], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm.isBusinessOrder ? _c('v-text-field', {
    staticClass: "mb-5",
    attrs: {
      "label": "First Name",
      "hide-details": "auto",
      "outlined": "",
      "disabled": _vm.useSameInfoEmployee,
      "rules": _vm.isBusinessOrder && !_vm.useSameInfoEmployee ? _vm.validate.input : [],
      "required": ""
    },
    model: {
      value: _vm.form.customer.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.form.customer, "firstName", $$v);
      },
      expression: "form.customer.firstName"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm.isBusinessOrder ? _c('v-text-field', {
    staticClass: "mb-5",
    attrs: {
      "rules": _vm.isBusinessOrder && !_vm.useSameInfoEmployee ? _vm.validate.input : [],
      "label": "Last Name",
      "hide-details": "auto",
      "disabled": _vm.useSameInfoEmployee,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.form.customer.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.form.customer, "lastName", $$v);
      },
      expression: "form.customer.lastName"
    }
  }) : _vm._e()], 1)], 1), _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm.isBusinessOrder ? _c('v-text-field', {
    staticClass: "mb-5",
    attrs: {
      "label": "Phone Number",
      "hide-details": "auto",
      "disabled": _vm.useSameInfoEmployee,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.form.customer.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form.customer, "phone", $$v);
      },
      expression: "form.customer.phone"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm.isBusinessOrder ? _c('v-text-field', {
    staticClass: "mb-5",
    attrs: {
      "rules": _vm.isBusinessOrder && !_vm.useSameInfoEmployee ? _vm.validate.email : [],
      "label": "E-Mail",
      "hide-details": "auto",
      "disabled": _vm.useSameInfoEmployee,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.form.customer.email,
      callback: function ($$v) {
        _vm.$set(_vm.form.customer, "email", $$v);
      },
      expression: "form.customer.email"
    }
  }) : _vm._e()], 1)], 1), _vm.includePrintable ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "subtitle-2 mb-0 danger--text font-weight-bold"
  }, [_vm._v(" You need to fill in the delivery adress if order include printable tickets ")])])], 1), _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12"
    }
  }, [_vm.isBusinessOrder ? _c('v-text-field', {
    staticClass: "mb-5",
    attrs: {
      "rules": _vm.isBusinessOrder && _vm.includePrintable ? _vm.validate.input : '',
      "label": "Address",
      "hide-details": "auto",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.form.customer.delivery.address,
      callback: function ($$v) {
        _vm.$set(_vm.form.customer.delivery, "address", $$v);
      },
      expression: "form.customer.delivery.address"
    }
  }) : _vm._e()], 1)], 1), _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm.isBusinessOrder ? _c('v-text-field', {
    staticClass: "mb-5",
    attrs: {
      "label": "C/O",
      "hide-details": "auto",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.form.customer.delivery.address2,
      callback: function ($$v) {
        _vm.$set(_vm.form.customer.delivery, "address2", $$v);
      },
      expression: "form.customer.delivery.address2"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm.isBusinessOrder ? _c('v-text-field', {
    staticClass: "mb-5",
    attrs: {
      "rules": _vm.isBusinessOrder && _vm.includePrintable ? _vm.validate.input : '',
      "label": "Postal Code",
      "hide-details": "auto",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.form.customer.delivery.postalCode,
      callback: function ($$v) {
        _vm.$set(_vm.form.customer.delivery, "postalCode", $$v);
      },
      expression: "form.customer.delivery.postalCode"
    }
  }) : _vm._e()], 1)], 1), _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm.isBusinessOrder ? _c('v-text-field', {
    staticClass: "mb-5",
    attrs: {
      "rules": _vm.isBusinessOrder && _vm.includePrintable ? _vm.validate.input : '',
      "label": "City",
      "hide-details": "auto",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.form.customer.delivery.city,
      callback: function ($$v) {
        _vm.$set(_vm.form.customer.delivery, "city", $$v);
      },
      expression: "form.customer.delivery.city"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.countries,
      "loading": _vm.isLoading,
      "rules": _vm.isBusinessOrder && _vm.includePrintable ? _vm.validate.select : '',
      "clearable": "",
      "hide-details": "",
      "return-object": "",
      "label": "Search for country...",
      "outlined": ""
    },
    model: {
      value: _vm.form.customer.delivery.country,
      callback: function ($$v) {
        _vm.$set(_vm.form.customer.delivery, "country", $$v);
      },
      expression: "form.customer.delivery.country"
    }
  })], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()], 1), _c('v-row', {
    staticClass: "actionSecBorder"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.prevStep();
      }
    }
  }, [_vm._v("Back to Order Type")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "accent",
      "disabled": _vm.form.invoice.company && _vm.form.invoice.company.limit.amount == 0,
      "block": ""
    },
    on: {
      "click": function ($event) {
        _vm.validateStep().then(_vm.nextStep);
      }
    }
  }, [_vm._v("Continue to order detail ")])], 1)], 1)], 1), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 3,
      "step": 3
    }
  }, [_vm._v(" Order Detail "), _c('small', [_vm._v("Add items to the order")])]), _c('v-stepper-content', {
    staticClass: "pb-5",
    attrs: {
      "step": 3
    }
  }, [_c('v-form', {
    ref: "form-3",
    staticClass: "mx-1 my-0 pt-3",
    model: {
      value: _vm.form.valid,
      callback: function ($$v) {
        _vm.$set(_vm.form, "valid", $$v);
      },
      expression: "form.valid"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Select Tenant",
      "items": _vm.isBusinessOrder ? _vm.businessOrderTenants : this.tenants,
      "item-text": "name",
      "item-value": "id",
      "rules": !_vm.isBusinessOrder ? _vm.validate.select : [],
      "outlined": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('v-avatar', {
          attrs: {
            "width": "32",
            "height": "32",
            "tile": ""
          }
        }, [_c('img', {
          staticStyle: {
            "margin": "5px"
          },
          attrs: {
            "src": 'https://assets.trainplanet.com/wlabel/' + _vm.fixUrl(item.name) + '/logos/favicon-32x32.png'
          }
        })]), _vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "item",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_c('v-avatar', {
          attrs: {
            "width": "32",
            "height": "32",
            "tile": ""
          }
        }, [_c('img', {
          staticStyle: {
            "margin": "5px"
          },
          attrs: {
            "src": 'https://assets.trainplanet.com/wlabel/' + _vm.fixUrl(item.name) + '/logos/favicon-32x32.png'
          }
        })]), _vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "append-outer",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "black"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref12) {
              var on = _ref12.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
            }
          }])
        }, [_c('span', [_vm._v("Choose tenant to create the right theme for customer’s offer.")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedTenant,
      callback: function ($$v) {
        _vm.selectedTenant = $$v;
      },
      expression: "selectedTenant"
    }
  }), _c('v-divider', {
    staticClass: "mb-5"
  }), [_c('v-sheet', {
    staticClass: "d-flex bg-surface-variant"
  }, [_c('v-sheet', {
    staticClass: "my-auto me-auto mb-5"
  }, [_c('h3', [_vm._v("Bookings")])]), _c('v-sheet', {
    staticClass: "mr-2 ml-2 pr-2 pl-2"
  }, [_c('v-switch', {
    staticClass: "my-auto",
    attrs: {
      "color": !_vm.includePrintable ? 'greeny' : 'grey',
      "label": "Free Shipping",
      "outlined": ""
    },
    model: {
      value: _vm.form.freeDistribution,
      callback: function ($$v) {
        _vm.$set(_vm.form, "freeDistribution", $$v);
      },
      expression: "form.freeDistribution"
    }
  })], 1), _c('v-sheet', {}, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "color": "black"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref13) {
        var on = _ref13.on;
        return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
      }
    }])
  }, [_c('span', [_vm._v(" Select this option if we're compensating the customer with"), _c('br'), _vm._v(" a compensation card and also want distribution to be free so they don't"), _c('br'), _vm._v(" pay anything. Keep in mind that you need to provide explanation in"), _c('br'), _vm._v(" the \"internal message\" field of the order if you're enabling free distribution.")])])], 1)], 1)], _vm._l(_vm.form.bookings, function (booking, i) {
    return _c('v-text-field', {
      key: i,
      staticClass: "booking-number",
      attrs: {
        "label": "Booking number",
        "rules": _vm.validate.orderNumber,
        "outlined": "",
        "required": ""
      },
      scopedSlots: _vm._u([{
        key: "prepend",
        fn: function () {
          return [_c('v-select', {
            staticClass: "text-capitalize",
            attrs: {
              "label": "System",
              "items": _vm.system.EBS,
              "rules": _vm.validate.select,
              "item-text": "label",
              "item-value": "value",
              "outlined": "",
              "required": ""
            },
            model: {
              value: booking.externalSystem,
              callback: function ($$v) {
                _vm.$set(booking, "externalSystem", $$v);
              },
              expression: "booking.externalSystem"
            }
          })];
        },
        proxy: true
      }, {
        key: "append",
        fn: function () {
          return [_c('v-icon', {
            attrs: {
              "color": "primary"
            },
            on: {
              "click": function ($event) {
                return _vm.removeBooking(i);
              }
            }
          }, [_vm._v("mdi-delete ")])];
        },
        proxy: true
      }, {
        key: "append-outer",
        fn: function () {
          return [_c('v-tooltip', {
            attrs: {
              "bottom": "",
              "color": "black"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref14) {
                var on = _ref14.on;
                return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
              }
            }], null, true)
          }, [_c('span', [_vm._v("Select booking system/ booking number to connect the order.")])])];
        },
        proxy: true
      }], null, true),
      model: {
        value: booking.externalOrderId,
        callback: function ($$v) {
          _vm.$set(booking, "externalOrderId", $$v);
        },
        expression: "booking.externalOrderId"
      }
    });
  }), _c('v-btn', {
    attrs: {
      "color": "accent",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.addBooking();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.bookings.length > 0 ? "Add another booking" : "Add booking") + " ")]), _c('v-divider', {
    staticClass: "mt-4"
  }), _vm.canUseGiftCards ? _c('h3', {
    staticClass: "mb-3 mt-3"
  }, [_vm._v("Gift Cards")]) : _vm._e(), _vm.form.giftCards.length > 0 ? _c('v-list', {
    staticClass: "mt-0 pt-0"
  }, _vm._l(_vm.form.giftCards, function (item, i) {
    return _c('v-list-item', {
      key: i,
      staticClass: "d-flex align-center py-1 px-3 rounded border999 mb-4"
    }, [_c('v-list-item-title', {
      staticClass: "black--text"
    }, [_vm._v(_vm._s(item.title1 + " " + item.title2) + " "), _c('b', {
      staticClass: "float-right mr-5"
    }, [_vm._v(_vm._s(item.amount) + " " + _vm._s(_vm.form.currency) + " x " + _vm._s(item.quantity) + " ")])]), _c('div', {
      staticClass: "d-flex"
    }, [_c('v-btn', {
      attrs: {
        "color": "third",
        "icon": ""
      },
      on: {
        "click": function ($event) {
          _vm.giftCard = item;
          _vm.giftCardIndex = i;
          _vm.openGiftCardModal(true);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
      attrs: {
        "icon": "",
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          _vm.form.giftCards = _vm.form.giftCards.slice(0, i);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1);
  }), 1) : _vm._e(), _vm.canUseGiftCards ? _c('v-btn', {
    attrs: {
      "color": "accent",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.openGiftCardModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.giftCards.length > 0 ? "Add another gift card for purchasing" : "Add gift card for purchasing") + " ")]) : _vm._e(), _vm.articles.length > 0 ? _c('h3', {
    staticClass: "mb-3 mt-3"
  }, [_vm._v("Additional Items")]) : _vm._e(), _vm.articles.length > 0 ? _c('v-combobox', {
    attrs: {
      "label": "Articles",
      "items": _vm.articles,
      "item-text": "title",
      "item-value": "slug",
      "small-chips": "",
      "hide-details": "",
      "multiple": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref15) {
        var attrs = _ref15.attrs,
          item = _ref15.item,
          parent = _ref15.parent,
          selected = _ref15.selected;
        return [item === Object(item) ? _c('v-chip', _vm._b({
          attrs: {
            "input-value": selected,
            "label": "",
            "small": ""
          }
        }, 'v-chip', attrs, false), [_c('span', {
          staticClass: "pr-2"
        }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-icon', {
          attrs: {
            "small": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, [_vm._v(" $delete ")])], 1) : _vm._e()];
      }
    }, {
      key: "append-outer",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "black"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref16) {
              var on = _ref16.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v("mdi-help-circle-outline")])];
            }
          }], null, false, 2575987712)
        }, [_c('span', [_vm._v("Add additional articles to the order. For flexible priced articles, you can leave the price boxes below empty and the system will choose the correct price depending on if the order is international or domestic.")])])];
      },
      proxy: true
    }], null, false, 843611546),
    model: {
      value: _vm.form.articles,
      callback: function ($$v) {
        _vm.$set(_vm.form, "articles", $$v);
      },
      expression: "form.articles"
    }
  }) : _vm._e(), _vm.form.articles.length > 0 ? _c('v-list', _vm._l(_vm.form.articles, function (item, i) {
    return _c('v-list-item', {
      key: i,
      class: 'd-flex align-center py-1 px-3 rounded border999 ' + (i < 1 ? 'mt-2' : 'mt-4')
    }, [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(item.title)
      }
    }), !item.flexiblePrice ? _c('b', {
      staticStyle: {
        "font-size": "20px",
        "width": "100px"
      }
    }, [_vm._v(_vm._s(item.prices.filter(function (r) {
      return r.currency === _vm.form.currency;
    }).map(function (p) {
      return p.amount;
    }).join("/")) + " " + _vm._s(_vm.form.currency))]) : _vm._e(), item.flexiblePrice ? _c('v-text-field', {
      staticClass: "font-weight-bold",
      attrs: {
        "disabled": !item.flexiblePrice,
        "hide-details": "",
        "label": item.prices.filter(function (r) {
          return r.currency === _vm.form.currency;
        }).map(function (p) {
          return p.amount;
        }).join('/'),
        "hint": "Price",
        "outlined": "",
        "type": "number",
        "dense": ""
      },
      model: {
        value: item.setPrice,
        callback: function ($$v) {
          _vm.$set(item, "setPrice", _vm._n($$v));
        },
        expression: "item.setPrice"
      }
    }) : _vm._e(), item.flexiblePrice ? _c('span', {
      staticClass: "currency",
      staticStyle: {
        "position": "absolute",
        "right": "30px",
        "top": "50%",
        "transform": "translateY(-50%)"
      }
    }, [_c('b', {
      staticStyle: {
        "font-size": "16px"
      }
    }, [_vm._v(_vm._s(_vm.form.currency))])]) : _vm._e()], 1);
  }), 1) : _vm._e(), _vm.isBusinessOrder && _vm.articles.length > 0 && _vm.form.invoice.company && !_vm.form.invoice.company.automaticInvoiceFeeEnabled ? _c('alert', {
    attrs: {
      "textBold": "Invoice Fee:",
      "text": "Since we have an agreement with the company,\n                      no invoice fee will be added."
    }
  }) : _vm._e(), _vm.isBusinessOrder && _vm.articles.length > 0 && _vm.form.invoice.company && _vm.form.invoice.company.automaticInvoiceFeeEnabled ? _c('alert', {
    attrs: {
      "textBold": "Invoice Fee:",
      "color": "greeny",
      "text": "Since we have an agreement with the company,\n                       invoice fee will be added automatically."
    }
  }) : _vm._e(), _c('div', [_c('v-divider')], 1), _c('v-row', [_c('v-col', [_c('h3', {
    staticClass: "mb-2 mt-3"
  }, [_vm._v("Message External")]), _c('vue-editor', {
    attrs: {
      "editor-toolbar": _vm.customToolbar
    },
    model: {
      value: _vm.form.externalMessage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "externalMessage", $$v);
      },
      expression: "form.externalMessage"
    }
  })], 1), _c('v-col', [_c('h3', {
    staticClass: "mb-2 mt-3"
  }, [_vm._v("Message Internal")]), _c('div', {
    attrs: {
      "id": "internalMessage"
    }
  }, [_c('vue-editor', {
    staticStyle: {
      "background-color": "#fff6d9"
    },
    attrs: {
      "editor-toolbar": _vm.customToolbar,
      "height": "70px"
    },
    model: {
      value: _vm.form.internalMessage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "internalMessage", $$v);
      },
      expression: "form.internalMessage"
    }
  })], 1)])], 1)], 2), _c('v-row', {
    staticClass: "actionSecBorder"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.prevStep();
      }
    }
  }, [_vm._v("Back to order information")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "accent",
      "block": ""
    },
    on: {
      "click": function ($event) {
        _vm.validateStep().then(_vm.checkArticles);
      }
    }
  }, [_vm._v("Continue to Order Preview ")])], 1)], 1)], 1), _c('v-stepper-step', {
    attrs: {
      "complete": _vm.step > 4,
      "step": 4
    }
  }, [_vm._v(" Order Preview "), _c('small', [_vm._v("Confirm that the generated order is correct before sending")])]), _c('v-stepper-content', {
    staticClass: "pb-5",
    attrs: {
      "step": 4
    }
  }, [_c('div', {
    staticClass: "mx-1 my-0 pt-3"
  }, [_vm.previewData && _vm.step === 4 ? _c('orderPreview', {
    attrs: {
      "formData": _vm.previewData,
      "orderId": _vm.orderId,
      "isBusinessOrder": _vm.isBusinessOrder
    }
  }) : _vm._e(), _vm.order && _vm.order.passengers && _vm.order.passengers.length > 0 ? _c('v-row', {
    staticClass: "mt-0 pt-0 mb-2"
  }, [_c('v-col', [_c('Passengers', {
    attrs: {
      "passengers": _vm.order.passengers
    }
  })], 1)], 1) : _vm._e(), _vm.order ? _c('Items', {
    attrs: {
      "items": _vm.order.items,
      "order-status": _vm.order.status,
      "order-is-paid": _vm.order.isPaid,
      "base-currency": _vm.order.calculatedPrice.base.currency,
      "tenant-id": _vm.order.seller.tenant.id,
      "locale": _vm.order.locale,
      "bookings": _vm.order.bookings
    }
  }) : _vm._e(), _vm.order ? _c('v-row', {
    staticClass: "actionSecBorder"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', {
    staticClass: "totalSummary"
  }, [_c('div', {
    staticClass: "col-6 pa-0"
  }, [_vm.canUseGiftCards && _vm.form.giftCards.length < 1 ? _c('div', {
    staticClass: "d-flex justify-start"
  }, [_c('v-text-field', {
    staticClass: "mr-3",
    attrs: {
      "label": "Gift Card Code",
      "hide-details": "",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.giftCardCode,
      callback: function ($$v) {
        _vm.giftCardCode = $$v;
      },
      expression: "giftCardCode"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "accent",
      "height": "auto",
      "width": "50%"
    },
    on: {
      "click": _vm.useGiftCard
    }
  }, [_vm._v("Use Gift Card ")])], 1) : _vm._e()]), _c('div', {
    staticClass: "d-flex flex-column col-6 pa-0"
  }, [_c('v-row', {
    staticClass: "ma-0 pa-0"
  }, [_c('v-col', {
    staticClass: "font-weight-bold col-8 ma-0 pa-0"
  }, [_vm._v("Sub Total : ")]), _c('v-col', {
    staticClass: "col-4 ma-0 pa-0"
  }, [_vm._v(_vm._s(_vm.order.calculatedPrice && _vm.order.calculatedPrice.subtotal.amountWithoutVatPlainText) + " ")])], 1), _c('v-row', {
    staticClass: "ma-0 pa-0"
  }, [_c('v-col', {
    staticClass: "font-weight-bold col-8 ma-0 pa-0"
  }, [_vm._v("Vat : ")]), _c('v-col', {
    staticClass: "col-4 ma-0 pa-0"
  }, [_vm._v(_vm._s(_vm.order.calculatedPrice && _vm.order.calculatedPrice.subtotal.vatPlainText) + " ")])], 1), _vm.order.calculatedPrice && _vm.order.calculatedPrice.discountFromGiftcards.amount > 0 ? _c('v-row', {
    staticClass: "ma-0 pa-0"
  }, [_c('v-col', {
    staticClass: "font-weight-bold col-8 ma-0 pa-0"
  }, [_vm._v("Paid with gift card : ")]), _c('v-col', {
    staticClass: "col-4 ma-0 pa-0"
  }, [_vm._v(_vm._s(_vm.order.calculatedPrice && _vm.order.calculatedPrice.discountFromGiftcards.plainText) + " ")])], 1) : _vm._e(), _c('v-row', {
    staticClass: "ma-0 pa-0"
  }, [_c('v-col', {
    staticClass: "font-weight-bold col-8 ma-0 pa-0 text-h6"
  }, [_vm._v("Total : ")]), _c('v-col', {
    staticClass: "col-4 ma-0 pa-0 font-weight-bold text-h6"
  }, [_vm._v(_vm._s(_vm.order.calculatedPrice && _vm.order.calculatedPrice.total.plainText) + " ")])], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.prevStep();
      }
    }
  }, [_vm._v("Back to Edit Order")]), _c('v-btn', {
    staticClass: "mt-3 accent",
    attrs: {
      "to": `/orders/${_vm.orderId}`,
      "block": ""
    }
  }, [_vm._v("Save And Close (No Offer) ")]), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "color": "black"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref17) {
        var on = _ref17.on;
        return [_c('v-btn', _vm._g({
          staticClass: "mt-3",
          attrs: {
            "color": "greeny",
            "dark": "",
            "block": ""
          },
          on: {
            "click": function ($event) {
              _vm.isBusinessOrder ? _vm.sendOfferToCompany() : _vm.showPreConfirmOrder = true;
            }
          }
        }, on), [_vm._v(" " + _vm._s(_vm.isBusinessOrder ? "Confirm and send offer to " + _vm.form.invoice.company.name : "Confirm and send payment link") + " ")])];
      }
    }], null, false, 2515466755)
  }, [_c('span', [_vm._v("The offer link will be sent to this mail : " + _vm._s(_vm.isBusinessOrder ? _vm.form.invoice.customer && _vm.form.invoice.customer.email : _vm.form.customer.email) + " ")])])], 1)], 1) : _vm._e()], 1)]), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "attach": true,
      "max-width": "500px"
    },
    model: {
      value: _vm.showPreConfirmOrder,
      callback: function ($$v) {
        _vm.showPreConfirmOrder = $$v;
      },
      expression: "showPreConfirmOrder"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.$store.state.loading
    }
  }, [_c('v-toolbar', {
    staticClass: "text-h5",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("Are you sure the order is correct? ")]), _c('v-card-text', {
    staticClass: "pt-5 pb-0 font-weight-bold"
  }, [_c('p', [_vm._v("Please make sure the order is correct. You can't make any changes to the order beyond this point.")]), _c('p', {
    staticClass: "primary--text"
  }, [_vm._v("An e-mail will be sent to the customer with details on how to pay the order.")])]), _c('v-card-actions', {
    staticClass: "pb-5"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.showPreConfirmOrder = false;
      }
    }
  }, [_vm._v("Cancel ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.confirmOrder();
      }
    }
  }, [_vm._v("Confirm order")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "attach": true,
      "max-width": "500px"
    },
    model: {
      value: _vm.showPrintableError,
      callback: function ($$v) {
        _vm.showPrintableError = $$v;
      },
      expression: "showPrintableError"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.$store.state.loading
    }
  }, [_c('v-toolbar', {
    staticClass: "text-h5",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("Error")]), _c('v-card-text', {
    staticClass: "pt-5 pb-0 font-weight-bold"
  }, [_c('p', [_vm._v(" The booking number you entered contains a printable ticket. Please enter the customer address information. ")])]), _c('v-card-actions', {
    staticClass: "pb-5 justify-end"
  }, [_c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.showPrintableError = false;
      }
    }
  }, [_vm._v("OK ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    on: {
      "click:outside": function ($event) {
        return _vm.closeGiftCardModal();
      }
    },
    model: {
      value: _vm.giftCardModal,
      callback: function ($$v) {
        _vm.giftCardModal = $$v;
      },
      expression: "giftCardModal"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.$store.state.loading
    }
  }, [_c('v-form', {
    ref: "giftCardForm",
    model: {
      value: _vm.giftCard.valid,
      callback: function ($$v) {
        _vm.$set(_vm.giftCard, "valid", $$v);
      },
      expression: "giftCard.valid"
    }
  }, [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dense": "",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Add New Gift Card to Purchase")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.closeGiftCardModal();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-container', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Title Line 1",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.giftCard.title1,
      callback: function ($$v) {
        _vm.$set(_vm.giftCard, "title1", $$v);
      },
      expression: "giftCard.title1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Title Line 2",
      "hide-details": "auto",
      "outlined": ""
    },
    model: {
      value: _vm.giftCard.title2,
      callback: function ($$v) {
        _vm.$set(_vm.giftCard, "title2", $$v);
      },
      expression: "giftCard.title2"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "amount",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Price Amount",
      "hide-details": "",
      "rules": _vm.validate.greater,
      "outlined": "",
      "type": "number"
    },
    model: {
      value: _vm.giftCard.amount,
      callback: function ($$v) {
        _vm.$set(_vm.giftCard, "amount", $$v);
      },
      expression: "giftCard.amount"
    }
  }), _c('span', {
    staticClass: "currency",
    staticStyle: {
      "position": "absolute",
      "right": "10px",
      "top": "50%",
      "transform": "translateY(-50%)"
    }
  }, [_c('b', {
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.form.currency))])])], 1)]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Quantity",
      "rules": _vm.validate.greater,
      "hide-details": "",
      "outlined": "",
      "type": "number"
    },
    model: {
      value: _vm.giftCard.quantity,
      callback: function ($$v) {
        _vm.$set(_vm.giftCard, "quantity", $$v);
      },
      expression: "giftCard.quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "label": "Message",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.giftCard.message,
      callback: function ($$v) {
        _vm.$set(_vm.giftCard, "message", $$v);
      },
      expression: "giftCard.message"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-end pb-5"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.closeGiftCardModal();
      }
    }
  }, [_vm._v("Close")]), _c('v-btn', {
    attrs: {
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.saveOrUpdateGiftCard();
      }
    }
  }, [_vm._v("Save Gift Card ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "400px",
      "show": ""
    },
    on: {
      "click:outside": function ($event) {
        _vm.articlesWarningDialog = false;
      }
    },
    model: {
      value: _vm.articlesWarningDialog,
      callback: function ($$v) {
        _vm.articlesWarningDialog = $$v;
      },
      expression: "articlesWarningDialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "text-h6 pl-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("There is no articles")]), _c('v-card-text', {
    staticClass: "pt-5 pb-0 font-weight-bold"
  }, [_c('p', [_vm._v(" " + _vm._s((_vm$user$fullName = _vm.user.fullName) !== null && _vm$user$fullName !== void 0 ? _vm$user$fullName : _vm.user.name) + ", did you forget to add service fee or cancellation protection? Do you want to continue without any articles? ")])]), _c('v-card-actions', {
    staticClass: "pb-5 justify-space-between"
  }, [_c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.articlesWarningDialog = false;
      }
    }
  }, [_vm._v("BACK TO EDIT ")]), _c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "color": "greeny",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        _vm.articlesWarningDialog = false;
        _vm.createOrUpdateOrder();
      }
    }
  }, [_vm._v("CONTINUE TO PREVIEW ")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }