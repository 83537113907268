var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.company.remainingLimit.amount < 1 ? _c('v-alert', {
    staticClass: "mb-2",
    attrs: {
      "type": "info",
      "dense": "",
      "color": "danger"
    }
  }, [_vm._v(" Contact administration before creating the order as the remaining limit is 0 SEK. ")]) : _vm._e(), _vm.company ? _c('v-card', {
    style: _vm.company.remainingLimit.amount < 1 ? 'background-color: #fff2f2' : '',
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-card-title', {
    staticClass: "accent--text medium"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Company Name")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.company.name ? _vm.company.name : "---"))])], 1), _c('v-col', [_c('v-card-title', {
    staticClass: "accent--text medium"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Organization Number")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.company.organizationNumber ? _vm.company.organizationNumber : "---"))])], 1), _c('v-col', [_c('v-card-title', {
    staticClass: "accent--text medium"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Other")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.company.reference ? _vm.company.reference : "---"))])], 1), _c('v-col', [_c('v-card-title', {
    staticClass: "accent--text medium"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Fortnox Number")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold black--text"
  }, [_vm._v(_vm._s(_vm.company.fortnoxNumber ? _vm.company.fortnoxNumber : "---"))])], 1), _c('v-col', [_c('v-card-title', {
    staticClass: "accent--text medium"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Remaning Limit")])]), _c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.company.remainingLimit.plainText))])], 1)], 1)], 1) : _vm._e(), _vm.company && _vm.company.comment ? _c('v-card', {
    staticClass: "mt-1",
    staticStyle: {
      "background-color": "#fff6d9"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-card-title', {
    staticClass: "black--text"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Internal Message")])]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.company.comment))])], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }