<template>
  <div>
    <v-alert
      v-if="company.remainingLimit.amount < 1"
      type="info"
      dense
      color="danger"
      class="mb-2"
    >
      Contact administration before creating the order as the remaining limit is 0 SEK.
    </v-alert>
    <v-card
      outlined
      v-if="company"
      :style="
        company.remainingLimit.amount < 1 ? 'background-color: #fff2f2' : ''
      "
    >
      <v-row>
        <v-col>
          <v-card-title class="accent--text medium">
            <span class="text-sm-body-2 font-weight-bold">Company Name</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold">{{
            company.name ? company.name : "---"
          }}</v-card-subtitle>
        </v-col>
        <v-col>
          <v-card-title class="accent--text medium">
            <span class="text-sm-body-2 font-weight-bold"
              >Organization Number</span
            >
          </v-card-title>

          <v-card-subtitle class="font-weight-bold">{{
            company.organizationNumber ? company.organizationNumber : "---"
          }}</v-card-subtitle>
        </v-col>

        <v-col>
          <v-card-title class="accent--text medium">
            <span class="text-sm-body-2 font-weight-bold">Other</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold">{{
            company.reference ? company.reference : "---"
          }}</v-card-subtitle>
        </v-col>
        <v-col>
          <v-card-title class="accent--text medium">
            <span class="text-sm-body-2 font-weight-bold">Fortnox Number</span>
          </v-card-title>

          <v-card-subtitle class="font-weight-bold black--text">{{
            company.fortnoxNumber ? company.fortnoxNumber : "---"
          }}</v-card-subtitle>
        </v-col>
        <v-col>
          <v-card-title class="accent--text medium">
            <span class="text-sm-body-2 font-weight-bold">Remaning Limit</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold">{{
            company.remainingLimit.plainText
          }}</v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      outlined
      v-if="company && company.comment"
      class="mt-1"
      style="background-color: #fff6d9"
    >
      <v-row>
        <v-col>
          <v-card-title class="black--text"
            ><span class="text-sm-body-2 font-weight-bold"
              >Internal Message</span
            ></v-card-title
          >
          <v-card-subtitle> {{ company.comment }}</v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    company: [],
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
</style>
