<template>
  <div v-if="formData">
    <toolbarTitle title="Order ID: " :titleRed="orderId" />
    <!-- <v-card outlined class="px-5 py-2 mb-2" v-if="orderId">
      <v-row>
        <v-col>
          <h3>Order ID : <span class="primary--text"> {{ orderId }}</span></h3>
        </v-col>
      </v-row>
    </v-card> -->
    <v-card class="pa-5" v-if="isBusinessOrder">
      <v-row>
        <v-col>
          <h3 style="border-bottom: 1px solid #ccc" class="pb-2">
            Company Information
          </h3>
        </v-col>
      </v-row>
      <v-row class="mt-2 pt-0">
        <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold">Company Name</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">
            {{
              formData.invoice.company.name ? formData.invoice.company.name : "---"
            }}</v-card-subtitle
          >
        </v-col>

        <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold"
              >Organization Number</span
            >
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">
            {{
              formData.invoice.company.organizationNumber
                ? formData.invoice.company.organizationNumber
                : "---"
            }}</v-card-subtitle
          >
        </v-col>

        <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold">Fortnox Number</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">
            {{
              formData.invoice.company.fortnoxNumber
                ? formData.invoice.company.fortnoxNumber
                : "---"
            }}</v-card-subtitle
          >
        </v-col>

        <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold"
              >Billing Reference</span
            >
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">{{
            formData.invoice.reference ? formData.invoice.reference : "---"
          }}</v-card-subtitle>
        </v-col>

        <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold">Other</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">{{
            formData.invoice.company.reference
              ? formData.invoice.company.reference
              : "---"
          }}</v-card-subtitle>
        </v-col>

        <!-- <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold">IATA-Number</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">
            {{
              formData.invoice.company.iataNumber ? formData.invoice.company.iataNumber : "---"
            }}</v-card-subtitle
          >
        </v-col> -->
      </v-row>
    </v-card>

    <v-card class="px-5 py-4 mt-3" v-if="isBusinessOrder">
      <v-row>
        <v-col>
          <h3 style="border-bottom: 1px solid #ccc" class="pb-2">
            Authorized Employee
          </h3>
        </v-col>
      </v-row>
      <v-row class="mt-2 pt-0">
        <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold">Employee</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">
            {{
              formData.invoice.customer.firstName
                ? formData.invoice.customer.firstName
                : "---"
            }}
            {{
              formData.invoice.customer.lastName
                ? formData.invoice.customer.lastName
                : "---"
            }}</v-card-subtitle
          >
        </v-col>

        <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold">Employee E-Mail</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">{{
            formData.invoice.customer.email
              ? formData.invoice.customer.email
              : "---"
          }}</v-card-subtitle>
        </v-col>

        <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold">Employee Phone</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">{{
            formData.invoice.customer.phone
              ? formData.invoice.customer.phone
              : "---"
          }}</v-card-subtitle>
        </v-col>

         <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold">IATA Number</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">{{
            formData.invoice.customer.companyCustomerNumber
              ? formData.invoice.customer.companyCustomerNumber
              : "---"
          }}</v-card-subtitle>
        </v-col>
      </v-row>



    </v-card>

    <v-card class="px-5 py-4 mt-3" v-if="isBusinessOrder">
      <v-row>
        <v-col>
          <h3 style="border-bottom: 1px solid #ccc" class="pb-2">
            Customer & Delivery Information
            <span class="text-body-2 primary--text font-weight-bold"
              >(The person who will receive the confirmation mail, Interrail
              Pass and pick up the printable tickets.)</span
            >
          </h3>
        </v-col>
      </v-row>
      <v-row class="mt-2 pt-0">
        <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold">Full Name</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0"
            >{{ formData.customer.firstName }}
            {{ formData.customer.lastName }}</v-card-subtitle
          >
        </v-col>

        <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold">Deliver E-Mail</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">{{
            formData.customer.email
          }}</v-card-subtitle>
        </v-col>

        <v-col cols="2" class="mt-0 pt-0">
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold">Phone</span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">{{
            formData.customer.phone
          }}</v-card-subtitle>
        </v-col>

        <v-col
          v-if="formData.customer.delivery.country"
          cols="2"
          class="mt-0 pt-0"
        >
          <v-card-title class="accent--text medium pl-0">
            <span class="text-sm-body-2 font-weight-bold"
              >Shipping Address
            </span>
          </v-card-title>
          <v-card-subtitle class="font-weight-bold pl-0">
            {{ formData.customer.delivery.address }}
            {{ formData.customer.delivery.address2 }} <br />
            {{ formData.customer.delivery.postalCode }}
            {{ formData.customer.delivery.city }}
            {{ formData.customer.delivery.country }}</v-card-subtitle
          >
        </v-col>
      </v-row>
    </v-card>

    <v-card class="px-5 py-4 mt-3" v-if="!isBusinessOrder">
      <v-row>
        <v-col cols="2">
          <span class="accent--text text-sm-body-2 font-weight-bold"
            >Customer E-Mail :</span
          >
            <span class="text-sm-body-2">
              {{
                formData.customer.email ? formData.customer.email : "---"
              }}</span
            >
        </v-col>
      </v-row>
    </v-card>

    <v-row>
    <v-col>
    <v-card class="px-5 py-4 mt-3" style="background-color: #fff6d9; min-height:150px;">
      <v-row>
        <v-col>
          <h3 style="border-bottom: 1px solid #ccc" class="pb-2">
            Internal Message
          </h3>
        </v-col>
      </v-row>
      <v-row class="mt-0 pt-0">
        <v-col
          v-html="formData.internalMessage ? formData.internalMessage : '---'"
        >
        </v-col>
      </v-row>
    </v-card>
    </v-col>
     <v-col>
    <v-card class="px-5 py-4 my-3" style="min-height:150px;">
      <v-row>
        <v-col>
          <h3 style="border-bottom: 1px solid #ccc" class="pb-2">
            External Message
          </h3>
        </v-col>
      </v-row>
      <v-row class="mt-0 pt-0">
        <v-col
          v-html="formData.externalMessage ? formData.externalMessage : '---'"
        >
        </v-col>
      </v-row>
    </v-card>
     </v-col>
    </v-row>
  </div>
</template>

<script>
import alert from "@/components/common/alert";
import toolbarTitle from "@/components/common/toolbarTitle";
export default {
  props: {
    formData: [],
    orderId: null,
    isBusinessOrder: null,
  },
  components: {
    toolbarTitle,
    alert,
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
</style>
