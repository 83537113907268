<template>
  <div>
    <toolbarTitle
      :title="
        step === 1
          ? 'Create Order'
          : isBusinessOrder === null || isBusinessOrder === false
          ? 'Create Private Order'
          : 'Create Business Order'
      " />

    <v-stepper v-model="step" vertical>
      <!-- ORDER TYPE STEP 1 -->
      <v-stepper-step :complete="step > 1" :step="1">
        Order Type
        <small>{{ userCanInvoiceOrders ? "Select order type" : "Select the action you want to do" }}</small>
      </v-stepper-step>
      <v-stepper-content :step="1" class="pb-5">
        <div class="py-1">
          <v-btn color="accent" @click="privateOrder(), nextStep()" style="height: 53px">
            {{ userCanInvoiceOrders ? "PRIVATE ORDER" : "CREATE ORDER" }}
          </v-btn>
          <v-btn
            v-if="userCanInvoiceOrders"
            color="accent"
            class="ml-4"
            @click="businessOrder(), nextStep()"
            style="height: 53px">
            BUSINESS ORDER
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- ORDER INFORMATION STEP 2 -->
      <v-stepper-step :complete="step > 2" :step="2">
        Order Information
        <small>Add basic information about the order</small>
      </v-stepper-step>

      <v-stepper-content :step="2" :loading="isLoading" class="pb-5">
        <v-form ref="form-2" v-model="form.valid" class="mx-1">
          <alert
            v-if="!isBusinessOrder"
            textBold="The rest of the customer's information will be fetched from Klarna, and they will be able to choose their own delivery address and method." />
          <v-autocomplete
            v-if="isBusinessOrder"
            v-model="form.invoice.company"
            :items="companies"
            :loading="isLoading"
            :search-input.sync="search"
            :rules="isBusinessOrder ? validate.select : []"
            @change="getCompanyAndCustomers()"
            clearable
            hide-details
            return-object
            item-text="key"
            item-value="id"
            label="Search for company..."
            outlined
            class="mb-5 mt-2">
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title> No data available</v-list-item-title>
              </v-list-item>
            </template>

            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>

            <template v-slot:item="{ item }">
              <v-list-item-avatar color="primary" class="text-h5 font-weight-light white--text">
                <v-icon color="#fff">mdi-domain</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.fullName ?? item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>

          <companyInfo v-if="form.invoice.company && isBusinessOrder" :company="form.invoice.company" class="mb-5" />

          <v-autocomplete
            v-if="form.invoice.company && isBusinessOrder"
            v-model="form.invoice.customer"
            :items="customers"
            :loading="isLoading"
            :search-input.sync="searchCustomer"
            :rules="isBusinessOrder ? validate.select : []"
            @change="authorizedEmplooye()"
            clearable
            hide-details
            return-object
            item-text="key"
            item-value="id"
            label="Search for authorized employee..."
            outlined
            class="mb-5">
            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span>Select the language for the customer</span>
              </v-tooltip>
            </template>

            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title> No data available</v-list-item-title>
              </v-list-item>
            </template>

            <template v-slot:selection="{ item }">
              {{ item.companyCustomerNumber ? item.companyCustomerNumber : item.firstName + " " + item.lastName }}
            </template>

            <template v-slot:item="{ item }">
              <v-list-item-avatar
                :color="item.companyCustomerNumber ? 'third' : 'primary'"
                class="text-h5 font-weight-light white--text">
                <v-icon color="#fff">{{ item.companyCustomerNumber ? "mdi-pound" : "mdi-account" }} </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <b v-if="item.companyCustomerNumber">IATA Number:</b>
                  {{
                    item.companyCustomerNumber
                      ? item.companyCustomerNumber + " " + item.firstName + " " + item.lastName
                      : item.firstName + " " + item.lastName
                  }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.email">{{ item.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <div class="mt-4 mb-5">
            <AccountAutoComplete
              v-if="!isBusinessOrder && accountForCustomers && accountForCustomers.length > 0"
              @modelChange="accountChange($event)" />
          </div>

          <!-- <v-autocomplete clearable v-if="!isBusinessOrder && accountForCustomers && accountForCustomers.length > 0"
            v-model="relationAccount" class="order-connection-autocomplete mb-5" style="height:56px"
            @update:modelValue="changeAccount($event)" outlined :items="accountForCustomers" item-value="id"
            item-text="fullName" dense hide-details :menu-props="{ maxHeight: '300px' }"
            placeholder="Account For Customer" label="Account For Customer" :persistent-hint="false">

            <template v-slot:selection="{ props, item }">
              <div class="d-flex flex-column gap-4">
                <div>
                  {{ item.fullName }}
                </div>
              </div>
            </template>

            <template v-slot:append-item>
              <div v-intersect="addAccount" />
            </template>

            <template v-slot:item="{ props, item }">
              <div class="d-flex flex-column gap-2">
                <div>
                  <strong>{{ item.fullName }}</strong>
                </div>
                <div style="font-size: 14px">
                  {{ item.email }}
                </div>
              </div>
            </template>

            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span>Account is optional</span>
              </v-tooltip>
            </template>
          </v-autocomplete> -->

          <v-text-field
            v-if="!isBusinessOrder"
            v-model="emailModel"
            :rules="!isBusinessOrder && !relationAccount ? validate.email : []"
            :disabled="relationAccount !== null"
            label="Customer E-mail (the payment link will be sent to this address)"
            hide-details="auto"
            outlined
            required
            class="mb-5">
            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span>The payment link will be sent to this mail</span>
              </v-tooltip>
            </template>
          </v-text-field>

          <v-select label="Language" :items="system.locales" v-model="form.locale" outlined required>
            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span>Select the language for the customer</span>
              </v-tooltip>
            </template>
          </v-select>

          <v-select
            label="Currency"
            :items="isBusinessOrder ? system.currencies.filter((x) => x.value === 'SEK') : system.currencies"
            v-model="form.currency"
            item-text="value"
            outlined
            required>
            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template disabled="false" v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span>Select the currency for customer</span>
              </v-tooltip>
            </template>
          </v-select>

          <v-text-field
            v-if="isBusinessOrder"
            v-model="form.invoice.reference"
            label="Reference"
            hide-details="auto"
            outlined
            required
            class="mb-5">
            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span>Example: Conference in Stockholm</span>
              </v-tooltip>
            </template>
          </v-text-field>
          <div v-if="isBusinessOrder">
            <div>
              <h3>Select Ticket Type</h3>
            </div>
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn :color="!includePrintable ? 'greeny' : 'grey'" dark large block @click="includePrintable = false"
                  >ONLY E-TICKETS
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn :color="includePrintable ? 'greeny' : 'grey'" dark large block @click="includePrintable = true"
                  >INCLUDE PRINTABLE TICKETS
                </v-btn>
              </v-col>
            </v-row>

            <div class="mt-5">
              <h3>Customer & Delivery Information</h3>
              <v-row>
                <v-col cols="12" sm="6">
                  <p class="subtitle-2 mb-0">
                    The person who will receive
                    <b>the confirmation mail, Interrail Pass</b> and pick up <b>the printable tickets</b>.
                    <span class="danger--text font-weight-bold">(NOT E-TICKETS)</span>
                  </p>
                </v-col>
                <v-col cols="12" sm="6" class="text-right">
                  <v-btn depressed @click="clearCustomerForm()">
                    <v-icon left> mdi-close </v-icon>
                    Clear Form
                  </v-btn>
                  <!-- <v-switch
                    v-model="useSameInfoEmployee"
                    @change="checkSameInfoEmployee()"
                    color="greeny"
                    hide-details
                    class="
                      float-right
                      v-input--reverse
                      ma-0
                      pa-0
                      font-weight-bold
                    "
                    label="Use same info as Authorized Employee info"
                  ></v-switch> -->
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-if="isBusinessOrder"
                  v-model="form.customer.firstName"
                  label="First Name"
                  hide-details="auto"
                  outlined
                  :disabled="useSameInfoEmployee"
                  :rules="isBusinessOrder && !useSameInfoEmployee ? validate.input : []"
                  required
                  class="mb-5"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-if="isBusinessOrder"
                  v-model="form.customer.lastName"
                  :rules="isBusinessOrder && !useSameInfoEmployee ? validate.input : []"
                  label="Last Name"
                  hide-details="auto"
                  :disabled="useSameInfoEmployee"
                  outlined
                  required
                  class="mb-5">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12" sm="6">
                <v-text-field
                  v-if="isBusinessOrder"
                  v-model="form.customer.phone"
                  label="Phone Number"
                  hide-details="auto"
                  :disabled="useSameInfoEmployee"
                  outlined
                  required
                  class="mb-5"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-if="isBusinessOrder"
                  v-model="form.customer.email"
                  :rules="isBusinessOrder && !useSameInfoEmployee ? validate.email : []"
                  label="E-Mail"
                  hide-details="auto"
                  :disabled="useSameInfoEmployee"
                  outlined
                  required
                  class="mb-5"></v-text-field>
              </v-col>
            </v-row>
            <div v-if="includePrintable">
              <v-row>
                <v-col cols="12">
                  <p class="subtitle-2 mb-0 danger--text font-weight-bold">
                    You need to fill in the delivery adress if order include printable tickets
                  </p>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-if="isBusinessOrder"
                    v-model="form.customer.delivery.address"
                    :rules="isBusinessOrder && includePrintable ? validate.input : ''"
                    label="Address"
                    hide-details="auto"
                    outlined
                    required
                    class="mb-5"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-if="isBusinessOrder"
                    v-model="form.customer.delivery.address2"
                    label="C/O"
                    hide-details="auto"
                    outlined
                    required
                    class="mb-5"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-if="isBusinessOrder"
                    v-model="form.customer.delivery.postalCode"
                    :rules="isBusinessOrder && includePrintable ? validate.input : ''"
                    label="Postal Code"
                    hide-details="auto"
                    outlined
                    required
                    class="mb-5"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-if="isBusinessOrder"
                    v-model="form.customer.delivery.city"
                    :rules="isBusinessOrder && includePrintable ? validate.input : ''"
                    label="City"
                    hide-details="auto"
                    outlined
                    required
                    class="mb-5"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete
                    v-model="form.customer.delivery.country"
                    :items="countries"
                    :loading="isLoading"
                    :rules="isBusinessOrder && includePrintable ? validate.select : ''"
                    clearable
                    hide-details
                    return-object
                    label="Search for country..."
                    outlined>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-form>

        <v-row class="actionSecBorder">
          <v-col cols="12" sm="6">
            <v-btn @click="prevStep()" block>Back to Order Type</v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <v-btn
              color="accent"
              :disabled="form.invoice.company && form.invoice.company.limit.amount == 0"
              @click="validateStep().then(nextStep)"
              block
              >Continue to order detail
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>

      <!-- ORDER DETAIL STEP 3 -->
      <v-stepper-step :complete="step > 3" :step="3">
        Order Detail
        <small>Add items to the order</small>
      </v-stepper-step>

      <v-stepper-content :step="3" class="pb-5">
        <v-form ref="form-3" v-model="form.valid" class="mx-1 my-0 pt-3">
          <v-select
            label="Select Tenant"
            :items="isBusinessOrder ? businessOrderTenants : this.tenants"
            v-model="selectedTenant"
            item-text="name"
            item-value="id"
            :rules="!isBusinessOrder ? validate.select : []"
            outlined
            required>
            <template v-slot:selection="{ item }">
              <v-avatar width="32" height="32" tile>
                <img
                  style="margin: 5px"
                  :src="'https://assets.trainplanet.com/wlabel/' + fixUrl(item.name) + '/logos/favicon-32x32.png'" />
              </v-avatar>
              {{ item.name }}
            </template>

            <template v-slot:item="{ item }">
              <v-avatar width="32" height="32" tile>
                <img
                  style="margin: 5px"
                  :src="'https://assets.trainplanet.com/wlabel/' + fixUrl(item.name) + '/logos/favicon-32x32.png'" />
              </v-avatar>
              {{ item.name }}
            </template>

            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span>Choose tenant to create the right theme for customer’s offer.</span>
              </v-tooltip>
            </template>
          </v-select>

          <v-divider class="mb-5"></v-divider>

          <template>
            <v-sheet class="d-flex bg-surface-variant">
              <v-sheet class="my-auto me-auto mb-5">
                <h3>Bookings</h3>
              </v-sheet>

              <v-sheet class="mr-2 ml-2 pr-2 pl-2">
                <v-switch
                  :color="!includePrintable ? 'greeny' : 'grey'"
                  v-model="form.freeDistribution"
                  label="Free Shipping"
                  outlined
                  class="my-auto" />
              </v-sheet>

              <v-sheet class="">
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                  </template>
                  <span>
                    Select this option if we're compensating the customer with<br />
                    a compensation card and also want distribution to be free so they don't<br />
                    pay anything. Keep in mind that you need to provide explanation in<br />
                    the "internal message" field of the order if you're enabling free distribution.</span
                  >
                </v-tooltip>
              </v-sheet>
            </v-sheet>
          </template>

          <v-text-field
            v-for="(booking, i) in form.bookings"
            :key="i"
            label="Booking number"
            v-model="booking.externalOrderId"
            :rules="validate.orderNumber"
            outlined
            required
            class="booking-number">
            <template v-slot:prepend>
              <v-select
                label="System"
                :items="system.EBS"
                :rules="validate.select"
                v-model="booking.externalSystem"
                item-text="label"
                item-value="value"
                class="text-capitalize"
                outlined
                required></v-select>
            </template>

            <template v-slot:append>
              <v-icon @click="removeBooking(i)" color="primary">mdi-delete </v-icon>
            </template>

            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span>Select booking system/ booking number to connect the order.</span>
              </v-tooltip>
            </template>
          </v-text-field>

          <v-btn color="accent" @click="addBooking()" block>
            {{ form.bookings.length > 0 ? "Add another booking" : "Add booking" }}
          </v-btn>

          <v-divider class="mt-4"></v-divider>

          <h3 v-if="canUseGiftCards" class="mb-3 mt-3">Gift Cards</h3>

          <v-list class="mt-0 pt-0" v-if="form.giftCards.length > 0">
            <v-list-item
              class="d-flex align-center py-1 px-3 rounded border999 mb-4"
              v-for="(item, i) in form.giftCards"
              :key="i">
              <v-list-item-title class="black--text"
                >{{ item.title1 + " " + item.title2 }}
                <b class="float-right mr-5"
                  >{{ item.amount }} {{ form.currency }} x
                  {{ item.quantity }}
                </b></v-list-item-title
              >
              <div class="d-flex">
                <v-btn
                  @click="
                    giftCard = item;
                    giftCardIndex = i;
                    openGiftCardModal(true);
                  "
                  color="third"
                  icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="form.giftCards = form.giftCards.slice(0, i)" icon color="primary">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-list-item>
          </v-list>
          <v-btn v-if="canUseGiftCards" color="accent" @click="openGiftCardModal()" block>
            {{ form.giftCards.length > 0 ? "Add another gift card for purchasing" : "Add gift card for purchasing" }}
          </v-btn>

          <h3 v-if="articles.length > 0" class="mb-3 mt-3">Additional Items</h3>

          <v-combobox
            v-if="articles.length > 0"
            label="Articles"
            v-model="form.articles"
            :items="articles"
            item-text="title"
            item-value="slug"
            small-chips
            hide-details
            multiple
            outlined>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip v-if="item === Object(item)" v-bind="attrs" :input-value="selected" label small>
                <span class="pr-2">
                  {{ item.title }}
                </span>
                <v-icon small color="primary" @click="parent.selectItem(item)"> $delete </v-icon>
              </v-chip>
            </template>

            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span
                  >Add additional articles to the order. For flexible priced articles, you can leave the price boxes
                  below empty and the system will choose the correct price depending on if the order is international or
                  domestic.</span
                >
              </v-tooltip>
            </template>
          </v-combobox>

          <v-list v-if="form.articles.length > 0">
            <v-list-item
              :class="'d-flex align-center py-1 px-3 rounded border999 ' + (i < 1 ? 'mt-2' : 'mt-4')"
              v-for="(item, i) in form.articles"
              :key="i">
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <b v-if="!item.flexiblePrice" style="font-size: 20px; width: 100px"
                >{{
                  item.prices
                    .filter((r) => r.currency === form.currency)
                    .map((p) => p.amount)
                    .join("/")
                }}
                {{ form.currency }}</b
              >

              <v-text-field
                v-if="item.flexiblePrice"
                :disabled="!item.flexiblePrice"
                hide-details
                :label="
                  item.prices
                    .filter((r) => r.currency === form.currency)
                    .map((p) => p.amount)
                    .join('/')
                "
                v-model.number="item.setPrice"
                hint="Price"
                outlined
                class="font-weight-bold"
                type="number"
                dense></v-text-field>
              <span
                v-if="item.flexiblePrice"
                style="position: absolute; right: 30px; top: 50%; transform: translateY(-50%)"
                class="currency">
                <b style="font-size: 16px">{{ form.currency }}</b>
              </span>
            </v-list-item>
          </v-list>
          <alert
            v-if="
              isBusinessOrder &&
              articles.length > 0 &&
              form.invoice.company &&
              !form.invoice.company.automaticInvoiceFeeEnabled
            "
            textBold="Invoice Fee:"
            text="Since we have an agreement with the company,
                        no invoice fee will be added." />
          <alert
            v-if="
              isBusinessOrder &&
              articles.length > 0 &&
              form.invoice.company &&
              form.invoice.company.automaticInvoiceFeeEnabled
            "
            textBold="Invoice Fee:"
            color="greeny"
            text="Since we have an agreement with the company,
                         invoice fee will be added automatically." />
          <div>
            <v-divider></v-divider>
          </div>

          <v-row>
            <v-col>
              <h3 class="mb-2 mt-3">Message External</h3>

              <vue-editor :editor-toolbar="customToolbar" v-model="form.externalMessage"> </vue-editor>
            </v-col>
            <v-col>
              <h3 class="mb-2 mt-3">Message Internal</h3>

              <div id="internalMessage">
                <vue-editor
                  :editor-toolbar="customToolbar"
                  style="background-color: #fff6d9"
                  height="70px"
                  v-model="form.internalMessage">
                </vue-editor>
              </div>
            </v-col>
          </v-row>
          <!-- <v-textarea
            v-model="form.externalMessage"
            hide-details="auto"
            outlined
            required
            rows="2"
            class="my-2"
          >
            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span
                  >This reference for define the journey (such as "Conference in
                  Stockholm")</span
                >
              </v-tooltip>
            </template></v-textarea
          > -->
        </v-form>

        <v-row class="actionSecBorder">
          <v-col cols="12" sm="6">
            <v-btn @click="prevStep()" block>Back to order information</v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <v-btn color="accent" @click="validateStep().then(checkArticles)" block>Continue to Order Preview </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
      <!--
                  form.articles.length < 1 && !articlesWarning
                    ? (articlesWarningDialog = true)
                    :  -->
      <!-- ORDER PREVIEW STEP 4 -->
      <v-stepper-step :complete="step > 4" :step="4">
        Order Preview
        <small>Confirm that the generated order is correct before sending</small>
      </v-stepper-step>

      <v-stepper-content :step="4" class="pb-5">
        <div class="mx-1 my-0 pt-3">
          <orderPreview
            v-if="previewData && step === 4"
            :formData="previewData"
            :orderId="orderId"
            :isBusinessOrder="isBusinessOrder" />
          <!-- <v-card
          > -->
          <!-- <v-row>
            <v-col>
              <h3 style="border-bottom: 1px solid #ccc" class="pb-2">
                Passengers
              </h3>
            </v-col>
          </v-row> -->
          <v-row class="mt-0 pt-0 mb-2" v-if="order && order.passengers && order.passengers.length > 0">
            <v-col>
              <Passengers :passengers="order.passengers" />
            </v-col>
          </v-row>
          <!-- </v-card> -->

          <Items
            v-if="order"
            :items="order.items"
            :order-status="order.status"
            :order-is-paid="order.isPaid"
            :base-currency="order.calculatedPrice.base.currency"
            :tenant-id="order.seller.tenant.id"
            :locale="order.locale"
            :bookings="order.bookings" />

          <!-- <div
            v-if="canUseGiftCards && form.giftCards.length < 1"
            class="d-flex my-4"
          >
            <v-text-field
              class="mr-3"
              v-model="giftCardCode"
              label="Gift Card Code"
              hide-details
              dense
              outlined
            ></v-text-field>
            <v-btn color="accent" @click="useGiftCard">Use Gift Card</v-btn>
          </div> -->

          <v-row class="actionSecBorder" v-if="order">
            <v-col cols="12" sm="6">
              <v-card class="totalSummary">
                <div class="col-6 pa-0">
                  <div v-if="canUseGiftCards && form.giftCards.length < 1" class="d-flex justify-start">
                    <v-text-field
                      class="mr-3"
                      v-model="giftCardCode"
                      label="Gift Card Code"
                      hide-details
                      dense
                      outlined></v-text-field>
                    <v-btn color="accent" height="auto" width="50%" @click="useGiftCard">Use Gift Card </v-btn>
                  </div>
                </div>

                <div class="d-flex flex-column col-6 pa-0">
                  <v-row class="ma-0 pa-0">
                    <v-col class="font-weight-bold col-8 ma-0 pa-0">Sub Total : </v-col>
                    <v-col class="col-4 ma-0 pa-0"
                      >{{ order.calculatedPrice && order.calculatedPrice.subtotal.amountWithoutVatPlainText }}
                    </v-col>
                  </v-row>
                  <v-row class="ma-0 pa-0">
                    <v-col class="font-weight-bold col-8 ma-0 pa-0">Vat : </v-col>
                    <v-col class="col-4 ma-0 pa-0"
                      >{{ order.calculatedPrice && order.calculatedPrice.subtotal.vatPlainText }}
                    </v-col>
                  </v-row>
                  <v-row
                    class="ma-0 pa-0"
                    v-if="order.calculatedPrice && order.calculatedPrice.discountFromGiftcards.amount > 0">
                    <v-col class="font-weight-bold col-8 ma-0 pa-0">Paid with gift card : </v-col>
                    <v-col class="col-4 ma-0 pa-0"
                      >{{ order.calculatedPrice && order.calculatedPrice.discountFromGiftcards.plainText }}
                    </v-col>
                  </v-row>
                  <v-row class="ma-0 pa-0">
                    <v-col class="font-weight-bold col-8 ma-0 pa-0 text-h6">Total : </v-col>
                    <v-col class="col-4 ma-0 pa-0 font-weight-bold text-h6"
                      >{{ order.calculatedPrice && order.calculatedPrice.total.plainText }}
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn @click="prevStep()" block>Back to Edit Order</v-btn>
              <v-btn class="mt-3 accent" :to="`/orders/${orderId}`" block>Save And Close (No Offer) </v-btn>

              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="greeny"
                    class="mt-3"
                    dark
                    @click="isBusinessOrder ? sendOfferToCompany() : (showPreConfirmOrder = true)"
                    block>
                    {{
                      isBusinessOrder
                        ? "Confirm and send offer to " + form.invoice.company.name
                        : "Confirm and send payment link"
                    }}
                  </v-btn>
                </template>
                <span
                  >The offer link will be sent to this mail :
                  {{ isBusinessOrder ? form.invoice.customer && form.invoice.customer.email : form.customer.email }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-stepper-content>

      <v-dialog v-model="showPreConfirmOrder" persistent :attach="true" max-width="500px">
        <v-card :disabled="$store.state.loading">
          <v-toolbar dark class="text-h5">Are you sure the order is correct? </v-toolbar>

          <v-card-text class="pt-5 pb-0 font-weight-bold">
            <p>Please make sure the order is correct. You can't make any changes to the order beyond this point.</p>

            <p class="primary--text">An e-mail will be sent to the customer with details on how to pay the order.</p>
          </v-card-text>

          <v-card-actions class="pb-5">
            <v-btn color="primary" @click="showPreConfirmOrder = false">Cancel </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="accent" @click="confirmOrder()">Confirm order</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showPrintableError" persistent :attach="true" max-width="500px">
        <v-card :disabled="$store.state.loading">
          <v-toolbar dark class="text-h5">Error</v-toolbar>
          <v-card-text class="pt-5 pb-0 font-weight-bold">
            <p>
              The booking number you entered contains a printable ticket. Please enter the customer address information.
            </p>
          </v-card-text>
          <v-card-actions class="pb-5 justify-end">
            <v-btn color="primary" class="float-right" @click="showPrintableError = false">OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="giftCardModal" max-width="700px" @click:outside="closeGiftCardModal()">
        <v-card :disabled="$store.state.loading">
          <v-form ref="giftCardForm" v-model="giftCard.valid">
            <v-toolbar fixed color="titlebg" class="title" dense dark>
              <v-toolbar-title>Add New Gift Card to Purchase</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeGiftCardModal()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-container>
              <v-row class="mt-3">
                <v-col cols="6">
                  <v-text-field
                    v-model="giftCard.title1"
                    label="Title Line 1"
                    hide-details="auto"
                    outlined></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="giftCard.title2"
                    label="Title Line 2"
                    hide-details="auto"
                    outlined></v-text-field>
                </v-col>
                <v-col cols="6">
                  <div class="amount" style="position: relative">
                    <v-text-field
                      v-model="giftCard.amount"
                      label="Price Amount"
                      hide-details
                      :rules="validate.greater"
                      outlined
                      type="number"></v-text-field>
                    <span
                      style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%)"
                      class="currency">
                      <b style="font-size: 20px">{{ form.currency }}</b>
                    </span>
                  </div>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="giftCard.quantity"
                    label="Quantity"
                    :rules="validate.greater"
                    hide-details
                    outlined
                    type="number"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="giftCard.message" outlined label="Message" hide-details clearable />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions class="justify-end pb-5">
            <v-btn color="primary" @click="closeGiftCardModal()">Close</v-btn>
            <v-btn color="accent" @click="saveOrUpdateGiftCard()">Save Gift Card </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="articlesWarningDialog" max-width="400px" show @click:outside="articlesWarningDialog = false">
        <v-card>
          <v-toolbar dark class="text-h6 pl-2">There is no articles</v-toolbar>
          <v-card-text class="pt-5 pb-0 font-weight-bold">
            <p>
              {{ user.fullName ?? user.name }}, did you forget to add service fee or cancellation protection? Do you
              want to continue without any articles?
            </p>
          </v-card-text>
          <v-card-actions class="pb-5 justify-space-between">
            <v-btn color="primary" class="float-right" @click="articlesWarningDialog = false">BACK TO EDIT </v-btn>
            <v-btn
              color="greeny"
              class="float-right"
              dark
              @click="
                articlesWarningDialog = false;
                createOrUpdateOrder();
              "
              >CONTINUE TO PREVIEW
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-stepper>
  </div>
</template>

<script>
import alert from "@/components/common/alert";
import toolbarTitle from "@/components/common/toolbarTitle";
import companyInfo from "@/components/info/company";
import orderPreview from "@/components/info/orderPreview";
import router from "@/router";
import AuthApi from "@/services/auth.api";
import store from "@/store";
import { debounce, fixUrl, validator } from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api";
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";
import countryList from "../../../static/countries.json";
import Items from "../components/Items.vue";
import Passengers from "../components/Passengers.vue";
import AccountAutoComplete from "@/views/Company/Company/AccountAutoComplete.vue";

export default {
  components: {
    alert,
    VueEditor,
    Passengers,
    Items,
    toolbarTitle,
    companyInfo,
    orderPreview,
    AccountAutoComplete,
  },
  data: () => ({
    accountLimit: 20,
    accountOffset: 0,
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      [{ color: [] }],
    ],
    orderId: null,
    accountForCustomers: [],
    code: "",
    relationAccount: null,
    giftCardModal: false,
    giftCardIndex: null,
    articlesWarningDialog: false,
    giftCardCode: "",
    showPreConfirmOrder: false,
    showPrintableError: false,
    orderData: null,
    previewData: null,
    canUseGiftCards: false,
    giftCard: {
      valid: false,
      title1: "",
      title2: "",
      message: "",
      amount: 0,
      quantity: 0,
    },
    countries: countryList,
    useSameInfoEmployee: false,
    isBusinessOrder: false,
    step: 1,
    isLoading: false,
    search: "",
    searchCustomer: "",
    includePrintable: false,
    companies: [],
    customers: [],
    system: {
      articles: [],
      locales: [],
      currencies: [],
      EBS: [],
    },
    selectedTenant: "",
    form: {
      valid: false,
      currency: null,
      locale: "sv-SE",
      freeDistribution: false,
      email: null,
      internalMessage: null,
      externalMessage: null,
      customer: {
        account: {
          id: null,
        },
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        delivery: {
          firstName: null,
          lastName: null,
          phone: null,
          email: null,
          address: null,
          address2: null,
          postalCode: null,
          city: null,
          country: null,
        },
      },
      invoice: [
        {
          company: null,
          reference: null,
          customer: null,
        },
      ],
      bookings: [
        {
          externalOrderId: null,
          externalSystem: "linkonline",
        },
      ],
      articles: [],
      giftCards: [],
    },
    selectableTenantsForBusinessOrder: [],
    validate: {
      greater: validator.greaterZeroCheck(),
      input: validator.required(),
      phone: validator.phone(),
      postalcode: validator.postalCode(),
      email: validator.email(),
      orderNumber: validator.orderNumber(),
      select: validator.select(),
    },
  }),
  created() {
    this.getToken();
    this.getTenant();
  },
  computed: {
    AuthModel() {
      return new AuthApi(this.tenantId, this.token);
    },
    ...mapGetters({
      tenants: "auth/userTenants",
      user: "auth/user",
      userCanInvoiceOrders: "auth/canInvoiceOrders",
    }),
    emailModel: {
      get() {
        return this.relationAccount ? this.relationAccount.email : this.form.email;
      },
      set(value) {
        if (!this.relationAccount) {
          this.form.email = value;
        }
      },
    },
    articles() {
      return this.system.articles.map((article) => {
        const response = article;

        if (this.form.locale && this.form.currency) {
          const translation = response.translations.find((x) => x.locale === "en-SE");

          const prices = response.prices.filter((x) => x.currency === this.form.currency);

          if (response.flexiblePrice && prices.length === 0) {
            response.prices.push({
              currency: this.form.currency,
              vat: 0,
              amount: 0,
            });
          }

          if (translation && prices.length > 0) {
            response.title = `${translation.title} (+ ${prices.map((p) => p.amount).join("/")} ${prices[0].currency})`;
          } else {
            response.title = `${translation.title}`;
          }
        }

        return response;
      });
    },
    order() {
      return this.orderData;
    },
    businessOrderTenants() {
      return this.tenants.filter((tenant) => this.selectableTenantsForBusinessOrder.includes(tenant.id));
    },
  },

  watch: {
    "form.articles": {
      deep: true,
      handler: function (value) {},
    },
    tenants: {
      deep: true,
      handler: function (value) {
        if (value) {
          this.$store.dispatch("loading", true);
          this.initSystemOptions();
        }
      },
    },
    search: {
      deep: true,
      handler: debounce(function (val) {
        // if (val && val.length > 3) {
        this.isLoading = true;
        Trainplanet.getCompanies({ q: val })
          .then((res) => {
            this.companies = res.company.map((r) => ({ ...r, key: val }));
          })
          .catch((err) => {
            this.$store.dispatch("error", err);
          })
          .finally(() => (this.isLoading = false));
      }, 300),
    },
    selectedTenant: {
      deep: true,
      handler: async function (value) {
        if (value) {
          this.$store.dispatch("loading", true);

          this.form.articles = [];
          this.system.articles = await Trainplanet.listOrderSystemArticles({
            tenantId: this.selectedTenant,
          });

          for (let i = 0; i < this.system.articles.length; i++) {
            const article = this.system.articles[i];

            // add service fee automatically
            if (article.slug === "service_fee") {
              this.form.articles.push(article);
              break;
            }
          }

          this.canUseGiftCards = this.tenants.find((item) => item.id === value).canUseGiftCards;
          this.$store.dispatch("loading", false);
        }
      },
    },
    searchCustomer: {
      deep: true,
      handler: debounce(function (val) {
        this.customers = [];
        this.isLoading = true;
        if (this.form.invoice.company) {
          Trainplanet.getCustomerList({
            q: val,
            companyId: this.form.invoice.company.id,
          })
            .then((res) => {
              this.customers = res.customers.map((r) => ({ ...r, key: val }));
            })
            .catch((err) => {
              this.$store.dispatch("error", err);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.customers = [];
        }
      }, 300),
    },
  },
  async mounted() {
    if (this.tenants.length > 0) {
      await this.initSystemOptions();
    }
  },
  methods: {
    accountChange(value) {
      this.form.email = null;
      this.relationAccount = value;
    },
    async getTenant() {
      const res = await this.$store.dispatch("auth/getUser");
      this.allTenants = res.tenants;
    },
    async getToken() {
      this.token = await store.dispatch("auth/acquireToken", router.history.current.meta.scopes);
      this.getAccountList(true);
    },
    addAccount() {
      this.accountLimit = this.accountLimit + 20;
      this.getAccountList();
    },
    async getAccountList() {
      const params = {
        limit: this.accountLimit,
        offset: 0,
        includeDeleted: false,
      };
      const response = await this.AuthModel.accountList(params);
      this.accountForCustomers = [];
      response.data.forEach((element) => {
        if (!element.isDeleted) {
          this.accountForCustomers.push(element);
        }
      });
    },
    fixUrl,
    async useGiftCard() {
      try {
        await this.$store.dispatch("loading", true);

        const body = {
          codes: [this.giftCardCode.trim()],
        };

        const res = await Trainplanet.useGiftCard(this.order.id, body);
        this.orderData = res.order;
        this.giftCardCode = "";
        await this.$store.dispatch("success", "Gift card has been used successfully!");
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error);
      } finally {
        await this.$store.dispatch("loading", false);
      }
    },
    saveOrUpdateGiftCard() {
      if (this.$refs[`giftCardForm`].validate()) {
        const body = { ...this.giftCard, amount: +this.giftCard.amount };
        if (this.giftCardIndex === 0 || this.giftCardIndex) {
          this.form.giftCards[this.giftCardIndex] = body;
        } else {
          this.form.giftCards.push(body);
        }
        this.giftCardModal = false;
        this.giftCard = {
          title1: "",
          title2: "",
          amount: 0,
          quantity: 0,
          message: "",
        };
        this.giftCardIndex = null;
      }
    },

    clearForm() {
      this.orderId = null;
      this.orderData = null;
      this.previewData = null;
      // this.canUseGiftCards = false;
      this.useSameInfoEmployee = false;
      this.search = "";
      this.searchCustomer = "";
      this.includePrintable = false;
      this.form.freeDistribution = false;
      this.form.locale = "sv-SE";
      this.form.currency = "SEK";
      this.form.email = null;
      this.form.internalMessage = null;
      this.form.externalMessage = null;
      this.form.customer = {
        account: { id: null },
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        delivery: {
          firstName: null,
          lastName: null,
          phone: null,
          email: null,
          address: null,
          address2: null,
          postalCode: null,
          city: null,
          country: null,
        },
      };
      this.form.invoice = {
        company: null,
        reference: null,
        customer: null,
      };
      this.form.bookings = [
        {
          externalOrderId: null,
          externalSystem: "linkonline",
        },
      ];
      this.form.articles = [];
      this.form.giftCards = [];
    },
    async openGiftCardModal() {
      this.giftCardModal = true;
    },
    async closeGiftCardModal() {
      this.giftCardModal = false;
      this.giftCard = {
        title1: "",
        title2: "",
        amount: 0,
        message: "",
      };
      this.giftCardIndex = null;
    },
    clearCustomerForm() {
      this.form.customer = {
        account: { id: null },
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        delivery: {
          firstName: null,
          lastName: null,
          phone: null,
          email: null,
          address: null,
          address2: null,
          postalCode: null,
          city: null,
          country: null,
        },
      };
    },
    checkSameInfoEmployee() {
      if (this.form.invoice.customer) {
        this.form.customer.firstName = this.form.invoice.customer.firstName;
        this.form.customer.lastName = this.form.invoice.customer.lastName;
        this.form.customer.lastName = this.form.invoice.customer.phone;
        this.form.customer.email = this.form.invoice.customer.email;
      } else {
        this.form.customer = {
          account: { id: null },
          firstName: null,
          lastName: null,
          phone: null,
          email: null,
          delivery: {
            firstName: null,
            lastName: null,
            phone: null,
            email: null,
            address: null,
            address2: null,
            postalCode: null,
            city: null,
            country: null,
          },
        };
      }
    },
    addBooking() {
      this.form.bookings.push({
        externalOrderId: null,
        externalSystem: "linkonline",
      });
    },
    removeBooking(key) {
      this.form.bookings.splice(key, 1);
    },
    authorizedEmplooye() {
      if (this.form.invoice.customer) {
        if (this.form.invoice.customer?.accountInfo?.id) {
          this.form.customer.account.id = this.form.invoice.customer.accountInfo.id;
          this.form.invoice.customer["account"] = this.form.customer.account;
          delete this.form.invoice.customer.accountInfo;
        } else {
          this.form.invoice.customer["account"] = null;
          delete this.form.invoice.customer.accountInfo;
        }

        this.form.customer.firstName = this.form.invoice.customer.firstName;
        this.form.customer.lastName = this.form.invoice.customer.lastName;
        this.form.customer.phone = this.form.invoice.customer.phone;
        this.form.customer.email = this.form.invoice.customer.email;
      }
    },
    checkArticles() {
      if (this.articles.length > 0 && this.form.articles.length === 0) {
        this.articlesWarningDialog = true;
      } else {
        this.createOrUpdateOrder();
      }
    },
    async createOrUpdateOrder() {
      try {
        await this.$store.dispatch("loading", true);
        if (this.isBusinessOrder) {
          this.form.customer.account = this.form.invoice.customer.account;
          this.form.customer.delivery.firstName = this.form.customer.firstName;
          this.form.customer.delivery.lastName = this.form.customer.lastName;
          this.form.customer.delivery.email = this.form.customer.email;
          this.form.customer.delivery.phone = this.form.customer.phone;
          if (!this.includePrintable) {
            this.form.customer.delivery.address = null;
            this.form.customer.delivery.address2 = null;
            this.form.customer.delivery.postalCode = null;
            this.form.customer.delivery.city = null;
            this.form.customer.delivery.country = null;
          } else {
            // TODO fix this causing problems when country is null
            let countryCode = this.form.customer.delivery.country.value;
            this.form.customer.delivery.country = countryCode;
          }
        } else {
          this.form.customer.email = this.relationAccount?.email ?? this.form.email;
          this.form.customer.delivery.email = this.form.email;
        }

        if (this.relationAccount) {
          this.form.customer.account = { id: this.relationAccount.id };
        } else if (this.isBusinessOrder) {
        } else {
          this.form.customer.account = null;
        }
        const order = {
          customer: this.form.customer,
          giftCards: this.form.giftCards,
          internalMessage: this.form.internalMessage,
          externalMessage: this.form.externalMessage,
          freeDistribution: this.form.freeDistribution,
          invoice: this.isBusinessOrder ? this.form.invoice : null,
          bookings: this.form.bookings.map((item) => {
            const booking = {};
            booking.externalSystem = item.externalSystem;
            booking.externalOrderId = item.externalOrderId;
            booking.externalCurrency = this.form.currency;
            return booking;
          }),
          articles: this.form.articles.map((item) => {
            const article = { slug: item.slug };

            if (item.setPrice) {
              article["price"] = {
                currency: this.form.currency,
                amount: item.setPrice,
              };
            }

            return article;
          }),
          tenantId: this.selectedTenant,
          currency: this.form.currency,
        };

        // We close update order part because we don't have proper back end solution for now. When we recreate update order end point we can open order update part

        // if (this.orderData) {
        //   this.orderData = await Trainplanet.updateOrder(
        //     this.orderData.id,
        //     { order },
        //     true,
        //     this.form.locale
        //   );
        // } else {
        const giftCards = [];
        order.giftCards.forEach((giftCard) => {
          for (let i = 0; i < +giftCard.quantity; i++) {
            giftCards.push({
              title1: giftCard.title1,
              title2: giftCard.title2,
              message: giftCard.message,
              amount: giftCard.amount,
            });
          }
        });
        order.giftCards = giftCards;

        this.orderData = await Trainplanet.createOrder(order, this.form.locale);
        this.previewData = this.form;
        this.orderId = this.orderData && this.orderData.id;
        if (this.orderData.printedItemIds && this.orderData.printedItemIds.length > 0 && !this.includePrintable) {
          this.includePrintable = true;
          this.step = 2;
          setTimeout(() => {
            this.showPrintableError = true;
            this.form.customer.delivery.country = {
              value: "SE",
              text: "Sweden",
            };
          }, 1000);
        } else {
          this.step++;
        }
        // }
      } catch (error) {
        await this.$store.dispatch("error", error.response?.data?.message || error.message || error);
      } finally {
        await this.$store.dispatch("loading", false);
      }
    },
    async confirmOrder() {
      await this.$store.dispatch("loading", true);
      await Trainplanet.sendOrderPaylink(this.orderData.id, this.form.locale);
      await this.$router.push({
        name: "orders-edit",
        params: {
          orderId: this.orderData.id,
        },
      });

      await this.$store.dispatch("loading", false);
    },
    async sendOfferToCompany() {
      this.$confirm("Are you sure you want to send the order offer to the company?", {
        color: "primary",
        title: "Send Offer",
      }).then(async (res) => {
        if (!res) return;
        try {
          await this.$store.dispatch("loading", true);
          await Trainplanet.sendOrderPaylink(this.orderData.id, this.form.locale, true);
          await this.$router.push({
            name: "orders-edit",
            params: {
              orderId: this.orderData.id,
            },
          });
        } catch (error) {
          await this.$store.dispatch("error", error.response.data.message);
        } finally {
          await this.$store.dispatch("loading", false);
        }
      });
    },
    validateStep() {
      return new Promise((resolve) => {
        if (this.$refs[`form-${this.step}`].validate()) {
          resolve(this.step);
        }
      });
    },
    getCompanyAndCustomers() {
      this.form.invoice.customer = null;
      const companyId = this.form.invoice.company.id;
      if (this.form.invoice.company) {
        this.isLoading = true;
        Trainplanet.getCustomerList({
          companyId: this.form.invoice.company.id,
        })
          .then((res) => {
            this.customers = res.customers;
          })
          .catch((err) => {
            this.$store.dispatch("error", err);
          })
          .finally(() => (this.isLoading = false));

        Trainplanet.getCompany(companyId)
          .then((res) => {
            this.selectableTenantsForBusinessOrder = res.tenants;
          })
          .catch((err) => {
            this.$store.dispatch("error", err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.customers = [];
      }
    },
    privateOrder() {
      this.isBusinessOrder = false;
      this.clearForm();
      this.selectedTenant = this.tenants.length == 1 ? this.tenants[0].id : null;
    },
    businessOrder() {
      this.isBusinessOrder = true;
      this.clearForm();
      this.selectedTenant = this.tenants.length > 0 ? this.tenants[0].id : null;
      this.form.customer.delivery.country = { value: "SE", text: "Sweden" };
    },
    prevStep() {
      return new Promise((resolve) => {
        this.step -= 1;
        resolve(this.step);
      });
    },
    nextStep() {
      return new Promise((resolve) => {
        this.step += 1;
        resolve(this.step);
      });
    },

    async initSystemOptions() {
      // this.selectedTenant = this.tenants[0].id;
      const { currencies, locales, EBS } = await Trainplanet.getOrderSystemOptions();
      this.system.currencies = Object.entries(currencies).map(([value, text]) => ({
        value,
        text,
      }));
      this.form.currency = this.system.currencies[0].value;
      this.system.locales = Object.entries(locales).map(([value, text]) => ({
        value,
        text,
      }));
      this.system.EBS = EBS;
      this.form.bookings.externalSystem = EBS;
      await this.$store.dispatch("loading", false);
    },
  },
};
</script>

<style scoped lang="scss">
.totalSummary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fbfbfb !important;
  border-radius: 10px;
  padding: 20px;
  text-align: right;
  min-height: 133px;
  border: thin solid rgba(0, 0, 0, 0.12);
}

#internalMessage .v-input__control {
  background-color: #fff6d9;
}

::v-deep .v-input--is-disabled {
  background-color: white;
}

::v-deep .v-input--is-disabled .v-input__control {
  background-color: #f7f7f7;
}
</style>
