var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.accountForCustomers ? _c('v-autocomplete', {
    staticClass: "account-connection-autocomplete",
    staticStyle: {
      "max-height": "40px",
      "min-height": "40px"
    },
    attrs: {
      "filter": _vm.filterObject,
      "loading": _vm.accountLoading,
      "items": _vm.accountForCustomers,
      "menu-props": {
        maxHeight: '200px'
      },
      "persistent-hint": false,
      "item-value": _vm.getItemValue,
      "label": "Account For Customer",
      "item-text": "fullName",
      "clean": "",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "update:search-input": _vm.handleInput
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var props = _ref.props,
          item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex flex-column gap-2"
        }, [_c('div', [_c('strong', [_vm._v(_vm._s(item.fullName))])]), _c('div', {
          staticStyle: {
            "font-size": "14px"
          }
        }, [_vm._v(" " + _vm._s(item.email) + " ")])])];
      }
    }], null, false, 1084542633),
    model: {
      value: _vm.selectedItem,
      callback: function ($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }