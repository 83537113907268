<template>
  <v-autocomplete
    v-if="accountForCustomers"
    v-model="selectedItem"
    @update:search-input="handleInput"
    :filter="filterObject"
    :loading="accountLoading"
    :items="accountForCustomers"
    :menu-props="{ maxHeight: '200px' }"
    :persistent-hint="false"
    :item-value="getItemValue"
    class="account-connection-autocomplete"
    label="Account For Customer"
    item-text="fullName"
    style="max-height: 40px; min-height: 40px"
    clean
    clearable
    outlined
    dense
    hide-details>
    <template v-slot:item="{ props, item }">
      <div class="d-flex flex-column gap-2">
        <div>
          <strong>{{ item.fullName }}</strong>
        </div>
        <div style="font-size: 14px">
          {{ item.email }}
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import AuthApi from "@/services/auth.api";
import router from "@/router";
import store from "@/store";

export default {
  name: "AccountAutoComplete",
  emits: ["modelChange"],
  components: {},
  props: {
    initItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    accountLoading: false,
    selectedItem: null,
    accountForCustomers: [],
    lastQuery: "",
  }),
  watch: {
    initItem: {
      handler: function () {
        if (!this.initItem.account?.id) this.selectedItem = null;
      },
    },
    selectedItem: {
      handler: function () {
        if (this.selectedItem !== undefined) this.$emit("modelChange", this.selectedItem);
      },
    },
    accountForCustomers: {
      handler: function () {
        if (this.initItem) this.initSelectedItem(this.initItem);
      },
    },
  },
  mounted() {
    this.getToken();
    this.getTenant();
  },

  computed: {
    AuthModel() {
      return new AuthApi(this.tenantId, this.token);
    },
    error: {
      get() {
        return this.$store.getters.error;
      },
      set(error) {
        this.$store.dispatch("error", error);
      },
    },
    success: {
      get() {
        setTimeout(() => {
          this.success = null;
        }, 3000);
        return this.$store.getters.success;
      },
      set(msg) {
        this.$store.dispatch("success", msg);
      },
    },
  },
  methods: {
    initSelectedItem(initItem) {
      this.selectedItem = this.accountForCustomers.find((item) => item.id === initItem?.account?.id);
    },
    getItemValue(item) {
      return item;
    },
    handleInput(value) {
      this.lastSelected = value;
      this.isLoading = true;
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => {
        if (this.initItem && this.initItem.account && value != this.initItem.account.fullName && value) {
          this.getAccountList(value, true);
        }

        if (!this.initItem) {
          this.getAccountList(value, true);
        }
        if (this.initItem && !this.initItem.accountInfo && !this.initItem.account) {
          this.getAccountList(value, true);
        }
      }, 300);
    },
    async getTenant() {
      const res = await this.$store.dispatch("auth/getUser");
      this.allTenants = res.tenants;
    },
    async getToken() {
      this.token = await store.dispatch("auth/acquireToken", router.history.current.meta.scopes);
      if (this.initItem) {
        if (this.initItem.account) {
          await this.getAccountList(this.initItem.account.fullName, false);
        }
        await this.getAccountList(null, false);
      } else {
        await this.getAccountList(null, true);
      }
    },
    filterObject(item, queryText, itemText) {
      return true;
    },
    async getAccountList(query, isEmpty) {
      const params = {
        includeDeleted: false,
        q: query ? query : null,
      };
      this.accountLoading = true;
      const response = await this.AuthModel.accountList(params);
      this.accountLoading = false;
      if (isEmpty) {
        this.accountForCustomers = [];
      }
      response.data.forEach((element) => {
        if (!element.isDeleted) {
          this.accountForCustomers.push(element);
        }
      });
    },
  },
};
</script>

<style></style>
